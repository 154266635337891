/*
 * @Description: 首页api接口
 * @Author: lucl
 * @Date: 2021-06-29 17:27:53
 * @LastEditors: lucl
 * @LastEditTime: 2021-06-29 17:32:19
 */
import {get} from '@/utils/request'

export function query(data){
    return get('/carousel/query',data)
}

export function servicePhone(){
    return get('/customer/service/phone')
}
