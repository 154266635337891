<template>
  <div class="page-warp">
    <div class="home-banner row">
        <div class="bg"><img src="../../assets/images/home/banner@2x.png" /></div>
    </div>
    <div class="row home-main">
        <div class="banner-text">
          <div class="text-con"><span @click="toCall">咨询热线：{{pageInfo.phone}}</span><span @click="openShare">添加企业微信获取更多</span></div>
        </div>
        <div class="row model-title">
          <span class="t">热门服务</span>
          <!-- <span class="m">更多 ></span> -->
        </div>
        <div class="row service-model">
          <!-- to service 之前的产品页 -->
          <div class="service-m-item it-3" @click="toPath('/service')">
            <div class="z-t"></div>
            <div class="e-t"></div>
          </div>
          <div class="service-m-item it-2" @click="userAllInfo.userType == 11 ? toPath('/individual') :''">
            <div class="z-t"></div>
            <div class="e-t"></div>
          </div>
          <div class="service-m-item it-1">
            <div class="z-t"></div>
            <div class="e-t"></div>
          </div>
        </div>
        <div class="row model-title" @click="toPath('/policy')">
          <span class="t">政策解读</span>
          <span class="m">更多 ></span>
        </div>
        <div class="row pro-model">
          <div class="pro-model-chil" v-for="(item,inx) in loopList" :key="inx">
              <div class="pro-model-item" @click="toPolicyDetail(item.id)">
                <div class="pro-model-text pr-10">
                  <div class="t">{{item.title}}</div>
                  <div class="d">{{item.createTime}}</div>
                </div>
                <div class="pro-model-img" v-if="item.pictureUrl">
                    <div class="img-box">
                        <img :src="item.pictureUrl.split(',')[0]"/>
                    </div>
                </div>
              </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { servicePhone } from '@/api/home'
import { pageQuery } from "@/api/policy"
import Moment from 'moment'
export default {
  watch:{
  },
  data() {
    return {
      pageInfo:{
        phone:'',
        time:''
      },
      page:1,
      pageSize:10,
      loopList:[],
      userAllInfo:{
        userType:''
      },
    };
  },
  computed: {},
  filters:{
  },
  created() {
      let userAllInfo = sessionStorage.getItem('userAllInfo');
      this.userAllInfo = userAllInfo ? JSON.parse(userAllInfo) : this.userAllInfo;
      this.getServicePhone();
      this.order();
  },
  mounted() {},
  methods: {
      Moment,
      openShare(){
        this.$emit('closeShareDialog');
      },
      toCall () {
          window.location.href = 'tel:'+this.pageInfo.phone;
      },
      getServicePhone(){
        servicePhone().then((res)=>{
          this.pageInfo.time = res.data.time
          this.pageInfo.phone = res.data.phone
        })
      },
      toPolicyDetail(res){
        this.$router.push({
          path:'/policyDetail',
          query:{
            id:res
          }
        })
      },
      toPath(url){
        this.$router.push({
          path:url
        })
      },
      async order(){
          let pramas={
            page:this.page,
            pageSize:this.pageSize
          }
          this.loading = true;
          await pageQuery (pramas).then(res=>{
              this.loopList = res.rows;
              this.loading = false;
          })
      },
  },
};
</script>
<style lang="less" scoped>
</style>